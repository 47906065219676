import ROUTES from "common/consts/routes";
import NavMenu from "common/interfaces/NavMenu";

export interface DesktopMenuCalcedProps {
    handleDesktopMenuClick: (menu: NavMenu) => void;
}

export type DesktopMenuProps = DesktopMenuCalcedProps;

export const DESKTOP_MENU: NavMenu[] = [
    {
      name: 'About',
      type: 'page',
      link: ROUTES.ABOUT,
      showIfNotLogin: true
    },
    {
      name: 'Contact',
      type: 'page',
      link: ROUTES.CONTACT,
      showIfNotLogin: true
    }
  ]