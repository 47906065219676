// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState, useRef, useEffect } from 'react';
import cubicBgLhs from 'assets/images/cubic-bg-lhs.png';
import newPerspectives from 'assets/videos/newPerspectives_1200x860_v01.mp4';
import growthDividend from 'assets/videos/growthDividend_1200x860_v01.mp4';
import powerOfConnection from 'assets/videos/powerOfConnection_1200x860_v01.mp4';
import equityRelease from 'assets/videos/equityRelease_1200x860_v01.mp4';
import './Styles.scss';

const HomeAnimation = ({ parentDimension }: any) => {
  const [currentIndex, setCurrentIndex] = useState<int>(0);

  /**
   * window size listener for video background
   */
  const inheritDimension = {
    height: parentDimension.height * 0.87,
    width:
      parentDimension.width > 950
        ? parentDimension.width * 0.66
        : parentDimension.width > 800
        ? '100%'
        : 790,
  };

  const headingGroups = [
    {
      text: [
        {value: 'A New Perspective.', color: '#C0C1C6', weightClass: 'font-light'}, 
        {value: 'A New Era of Investing.', color: '#FFFFFF', weightClass: 'font-light'}
      ],
    },
    {
      text: [
        {value: 'Empowering Investors', color: '#FFFFFF', weightClass: 'font-light'},
        {value: <span>with <span className="font-bold">New Instruments</span></span>, color: '#FFFFFF', weightClass: 'font-light'},
        {value: 'of Precision.', color: '#FFFFFF', weightClass: 'font-bold'},
      ],
    },
    {
      text: [
        {value:'Revealing New Market', color: '#FFFFFF', weightClass: 'font-light'},
        {value:<span>Infrastructure, <span className="font-bold">Powered</span></span>, color: '#FFFFFF', weightClass: 'font-light'},
        {value:'by Connection.', color: '#FFFFFF', weightClass: 'font-bold'},
      ],
    },
    {
      text: [
        {value: 'Optimisation through', color: '#FFFFFF', weightClass: 'font-light'}, 
        {value: 'Greater Capital Efficiency.', color: '#FFFFFF', weightClass: 'font-bold'},
      ],
    },
  ];

  const updateCurrentIndex = () => {
    if (currentIndex < headingGroups.length - 1) {
      setCurrentIndex((current) => current + 1);
    } else {
      setCurrentIndex(0);
    }
  }

  useEffect(() => {
    const indexTimer = setInterval(() => {
      updateCurrentIndex();
    }, 5000);
    return () => {
      clearInterval(indexTimer);
    };
  }, [currentIndex]);

  const videoDimensions = {
    width: inheritDimension.width,
    maxWidth: inheritDimension.width,
    height: inheritDimension.height,
    maxHeight: inheritDimension.height,
  };

  return (
    <div className="w-full h-full home-animation">
      <div
        className="video-container"
        key={currentIndex}
      >
        <div className="content">
          {headingGroups[currentIndex].text.map((text) => (
            <h1 className={`fade text-[${text.color}] text-[52px] ${text.weightClass}`}>{text.value}</h1>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeAnimation;
