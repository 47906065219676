import React from 'react';
import { MarketsETAContentProps } from './MarketsETAContent.props';
import cn from 'classnames';
import { ReactComponent as Plus } from 'assets/svg/plus.svg';
import { numberFormatter } from 'common/utils/String';
import { getSplitTitle } from 'common/utils/String';
import { MarketsSecurity } from 'common/interfaces/Markets/MarketsSecurity';
import useSelectorSafe from 'store/selectors/useSelectorSafe';
import './styles.scss';

const MarketsETAContent: React.FC<MarketsETAContentProps> = (
  props: MarketsETAContentProps
) => {
  const { prism } = useSelectorSafe((state) => state) || {};

  const handleClick = (e: React.MouseEvent) => {
    let security = {} as MarketsSecurity;
    if (props.security) {
      security = props.security;
    } else {
      security = prism?.securities.find(
        (security) => security.ticker === props.ticker
      ) as MarketsSecurity;
    }

    //Redirects to default view (Perspective or Optimiser) and selects the selected security and eta and shown to the page
    props.handleETAClick(props.eta.etaType, security);
  };

  return (
    <div
      className="text-[#FFFFFFE5] w-full flex-row mb-1 p-[4px] text-base cursor-pointer flex tracking-wider ltr bg-eta-item items-center"
      key={props.eta.etaType}
      onClick={handleClick}
    >
      <div className={`w-[27px] h-[27px] rounded-full background-${props.eta.etaType}`} />
      <div className="w-[10%] flex flex-row eta-type-col">
        <div className="px-2 text-xs flex flex-col justify-center asx-row">
          <span className="tracking-normal font-bold">
            <span>{getSplitTitle(props.eta.etaType, 0)}</span>
            <span className="font-normal">
              {getSplitTitle(props.eta.etaType, 1)}
            </span>
            <sup>ETA</sup>
            <br />
          </span>

          <span className="font-bold">{props.ticker}</span>
        </div>
      </div>
      <div className="w-[14%] text-center pl-2">
        <span>{props.eta.remainingTerm}Yrs</span>
      </div>
      <div className="w-[14%] text-center">
        <span
          className={cn(
            props.eta.change > 0 ? 'text-[#38D41F]' : 'text-red-500'
          )}
        >
          {props.eta.change > 0 ? '+' : ''}
          {props.eta.change}
        </span>
      </div>
      <div className="w-[14%] text-center">
        {props.eta.last.toFixed(2)}{props.type === 'MULTIPLE' ? 'X' : '%'}
      </div>
      <div className="w-[14%] text-center">
        {props.eta.offer.toFixed(2)}{props.type === 'MULTIPLE' ? 'X' : '%'}
      </div>
      <div className="w-[14%] text-center">
        {props.eta.matchDistance}%
      </div>
      <div className="w-[14%] text-center">
        ${numberFormatter(props.eta.valueInCirculation)}
      </div>
    </div>
  );
};

export default MarketsETAContent;
