const ETAPairingColors = {
  MaxGrowth: 'Blue',
  PureGrowth: 'Green',
  GrowthGuard: 'Red',
  MaxDiv: 'Red',
  PureDiv: 'Green',
  DivGuard: 'Blue',
  UltraGrowth: 'Purple',
  UltraGuard: 'Purple'
};

export default ETAPairingColors;
