import { useState } from 'react';
import { MarketsTableBodyContentProps } from './MarketsTableBodyContent.props';
import { REGIONS } from 'common/consts';
import MarketsTableETA from '../MarketsTableETA';
import { CURRENCY_SYMBOLS, CURRENCIES } from 'common/consts';
import cn from 'classnames';
import { numberFormatter } from 'common/utils/String';
import { Tooltip } from 'antd';

const MarketsTableBodyContentView: React.FC<MarketsTableBodyContentProps> = (
  props: MarketsTableBodyContentProps
) => {
  const [shown, setShown] = useState<boolean>(false);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  return (
    <div
      className='markets-table-row ltr cursor-pointer relative'
      // onMouseOver={() => {
      //   setOpenTooltip(true);
      // }}
      // onMouseLeave={() => {
      //   setOpenTooltip(false);
      // }}
    >
      <div
        className={cn("flex flex-row font-din2014 text-[16px]", 
          shown ? "active-security font-bold" : "bg-dark-header-linear-gradient font-light"
        )}
        onClick={() => {
          setShown(!shown);
        }}
      >
        <div className="w-[15%] p-2 flex items-center justify-center underlying-security-col">
          <Tooltip
            title={props.security.longName}
            placement="right"
            overlayClassName="uppercase markets-tooltip"
            color="black"
            open={openTooltip}
            destroyTooltipOnHide
          >
            <span className="tracking-wider">
              {props.security.ticker}
            </span>
          </Tooltip>
        </div>
        <div className="w-[8%] flex items-center pl-2.5 last-price-col">
          <div>
            <span className="font-dinCondensed text-xs">
              {CURRENCIES.get(props.security.region)}
            </span>{' '}
            {props.security.lastPrice.toFixed(2)}
          </div>
        </div>
        <div className="w-[7%] flex items-center pl-2.5 change-col">
          <span
            className={cn(
              'w-[50%]',
              props.security.chgNet1d > 0 ? 'text-[#38D41F]' : 'text-red-500'
            )}
          >
            {props.security.chgNet1d > 0 ? '+' : ''}
            {props.security.chgNet1d}
          </span>
        </div>
        <div className="w-[10%] flex items-center pl-2.5 divident-yield-col">
          {(props.security.forwardDivYield * 100).toFixed(2)}%
        </div>
        <div className="w-[10%] flex items-center pl-2.5 dividend-payout-ratio-col">
          {props.security.dvdPayoutRatio}%
        </div>
        <div className="w-[10%] lowercase flex items-center pl-2.5 hide-mobile">
          {CURRENCY_SYMBOLS.get(props.security.region)}
          {numberFormatter(props.security.etaValueInCirculation)}
        </div>
        <div className="w-[8%] flex items-center pl-2.5 hide-mobile">
          {props.security.peRatioEod}
        </div>
        <div className="w-[8%] flex items-center pl-2.5 hide-mobile">
          {CURRENCY_SYMBOLS.get(props.security.region)}
          {numberFormatter(props.security.marketCap).replace('K', 'B')}
        </div>
        <div className="w-[6%] flex items-center pl-2.5 hide-mobile">
          {/* {props.security.region} */}
          {
            REGIONS.find((region) => region.value === props.security.region)
              ?.name
          }
        </div>
        <div className="w-[8%] flex items-center pl-2.5 hide-mobile">
          {props.security.sector}
        </div>
        <div className="w-[10%] flex items-center pl-2.5 hide-mobile">
          {props.security.industry}
        </div>
      </div>
      <MarketsTableETA
        security={props.security}
        shown={shown}
        selectedEtas={props.selectedEtas}
        handleETAClick={props.handleETAClick}
      />
      <div className="absolute inset-0 security-border-bottom"></div>
    </div>
  );
};

export default MarketsTableBodyContentView;
