import { CURRENCY_SYMBOLS } from 'common/consts';

interface SecurityHeaderProps {
	security: any;
	yieldValue: any;
}

function SecurityHeader({security, yieldValue}: SecurityHeaderProps) {
  
  if (!security?.ticker) return null;

	return (
		<>      
          <div className="flex-shrink-1">
            <div className="text-white text-[25px] font-light">{CURRENCY_SYMBOLS.get(security?.region)}{security?.lastPrice}</div>
            <div className="text-[#C0C1C6] text-[14px]">Underlying Price</div>
          </div>

          <div className="horizontal-separator" />

          <div>
            <div className="text-white text-[28px] font-bold text-center">{security?.ticker}</div>
            <div className="text-[#C0C1C6] text-[14px] text-center uppercase">{security?.longName}</div>
          </div>

          <div className="horizontal-separator" />

          <div className="flex-shrink-1">
            <div className="text-white text-[25px] font-light text-right">{yieldValue}%</div>
            <div className="text-[#C0C1C6] text-[14px] text-right">Underlying Yield</div>
          </div>
        </>
	)
}

export default SecurityHeader;
