import { DesktopMenuProps } from './DesktopMenu.props';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ROUTES from "common/consts/routes";
import { DESKTOP_MENU } from './DesktopMenu.props';
import { AuthContext } from 'context/AuthContext';
import { MainContext } from 'context/MainContext';
import { useContext, useState } from 'react';
import { Input, Radio, RadioChangeEvent } from 'antd';
import './styles.scss';

const visualiseRoutes = ['/home', '/perspective', '/optimiser']

const DesktopMenuView: React.FC<DesktopMenuProps> = (
  props: DesktopMenuProps
) => {
  const { isLogin } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { showMarkets, setShowMarkets, searchValue, setSearchValue } = useContext(MainContext);
  const [ selectedType, setSelectedType ] = useState(ROUTES.PERSPECTIVE);

  const handleOpenETAMarkets = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    setShowMarkets(true);
    localStorage.setItem('defaultLightBox', 'EtaMarkets');
    if (!visualiseRoutes.includes(location.pathname)) navigate(selectedType)
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }

  const onChange = (event: RadioChangeEvent) => {
    setSelectedType(event.target.value);
    navigate(`${event.target.value}${location.search}`);
  }

  return (
    <>
      {isLogin && <div className="flex">
        <Radio.Group className="min-w-fit !mr-[14px]" onChange={onChange} defaultValue={ROUTES.PERSPECTIVE}>
          <Radio.Button className="prism-radio-button" value={ROUTES.PERSPECTIVE}>Perspective</Radio.Button>
          <Radio.Button className="prism-radio-button" value={ROUTES.OPTIMISER}>Optimiser</Radio.Button>
        </Radio.Group>
        <Input
          className="contact-input-base contact-input max-w-sm"
          placeholder="Explore ETAs"
          onFocus={handleOpenETAMarkets}
          onChange={handleSearch}
        />
      </div>}
      <ul className="font-din2014 flex pl-5 items-center justify-end h-full gap-x-8 uppercase text-[17px] mb-0">
        {DESKTOP_MENU.map((menu, index) => {
          let isActive = menu.link.split('?')[0] === location.pathname;
          return (
            <NavLink
              className={`h-full flex items-center hover:text-white ${
                isActive ? 'text-white border-t border-white' : 'text-[#C0C1C6]'
              } ${!isLogin && !menu.showIfNotLogin ? 'invisible' : ''}`}
              to={menu.link}
              onClick={() => {
                props.handleDesktopMenuClick(menu);
              }}
              key={index}
            >
              {menu.name}
            </NavLink>
          );
        })}
      </ul>
    </>
  );
};

export default DesktopMenuView;
