import React, { useState, useContext } from 'react';
import clsx from 'classnames';
import PrismLogo from 'assets/svg/prism-logo.svg';
import { useNavigate } from 'react-router-dom';
import { MainContext } from 'context/MainContext';
import { AuthContext } from 'context/AuthContext';
import ROUTES from 'common/consts/routes';
import { ReactComponent as Hamburger } from 'assets/svg/hamburger.svg';
import DesktopMenu from '../DesktopMenu';
import MobileMenu from '../MobileMenu';

const ToolbarView: React.FC = () => {
  const navigate = useNavigate();
  const { expand, setExpand } = useContext(MainContext);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { isLogin } = useContext(AuthContext);

  /**
   * @description Redirect to the home page, and resets the left hand tab
   */
  const handleClickLogo = () => {
    closeMenu();
    navigate(ROUTES.HOME);
  };

  /**
   * @description Closes the mobile menu
   */
  const closeMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <>
      <div className="flex w-full h-full px-5 lg:px-[50px] justify-around lg:justify-start">
        <div className="flex lg:hidden items-center w-1/4"></div>
        <div className={clsx("flex px-2 h-full items-center justify-center lg:justify-start flex-no-shrink text-black transition-[width] duration-300", expand ? 'w-2/3' : 'w-1/3')}>
          <img
            src={PrismLogo}
            alt="Prism Logo"
            className="cursor-pointer"
            onClick={handleClickLogo}
          />
        </div>
        <div className={clsx("px-2 h-full hidden lg:flex items-center transition-[width] duration-300", expand ? 'w-1/3' : 'w-2/3', isLogin ? 'justify-between' : 'justify-end')}>
          <DesktopMenu />
        </div>
        <div className="flex lg:hidden items-center w-1/4 justify-end">
          <Hamburger
            onClick={() => {
              setShowMobileMenu(!showMobileMenu);
            }}
          />
        </div>
      </div>
      {showMobileMenu && (
        <MobileMenu
          closeMenu={closeMenu}
        />
      )}
    </>
  );
};

export default ToolbarView;
