import { ETAPairing, Nullable } from 'common/types';
import { ISecurity } from 'common/interfaces';
import { EquityReleaseCalculator } from 'hooks/useEquityOptimiser';
import { SecurityDropdown } from 'components/snippets';
import SmallCube from 'assets/svg/small_cube.svg';
import SecurityHeader from 'components/common/SecurityHeader';

const EquityTopBar: React.FC<{
  calc: EquityReleaseCalculator;
  etaPairing: Nullable<ETAPairing>;
  hideSecurities?: boolean;
  security: Nullable<ISecurity>;
  securities?: ISecurity[];
  handleETAChange: (etaColor: ETAPairing) => void;
  handleSecurityChange: (security: ISecurity) => void;
  toggleETASelector: (hidden: boolean) => void;
  handleClickMarkets: () => (e: React.MouseEvent) => void;
}> = ({
  calc,
  etaPairing = null,
  hideSecurities = false,
  security = null,
  securities = [],
  ...props
}) => {

  return (
    <>
      {security && <SecurityHeader security={security} yieldValue={calc?.forwardDivYield} />}
    </>
  );
};

export default EquityTopBar;
