// const REGIONS = ['AU', 'US', 'UK', 'EUR'];
const REGIONS = [
    {
        name: 'AU',
        value: 'AU'
    },
    {
        name: 'US',
        value: 'US'
    },
    {
        name: 'UK',
        value: 'LN'
    },
    {
        name: 'EUR',
        value: 'EUR'
    }
]

export default REGIONS;