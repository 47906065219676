import { ETATabProps } from './ETATab.props';
import cn from 'classnames';

const ETATabView: React.FC<ETATabProps> = (props: ETATabProps) => (
  <svg
    width={props.width}
    height={props.height ?? 38}
    viewBox={`0 0 ${props.width} ${props.height ?? 38}`}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    className='cursor-pointer'
  >
    {props.path}
    <foreignObject x="0" y="0" width={props.width} height={props.height ?? 38}>
      <div className={cn("text-center pt-[1px]", props.stroke && `text-[${props.stroke}]`)}>{props.children}</div>
    </foreignObject>
  </svg>
);

export default ETATabView;
