import React, { useState } from 'react';
import { MarketsProps } from './Markets.props';
import MarketsTableHeader from 'components/fragments/Markets/MarketsTableHeader';
import MarketsTableBody from 'components/fragments/Markets/MarketsTableBody';
import MarketsAlphabet from 'components/fragments/Markets/MarketsAlphabet';
import BackToTop from 'components/fragments/Markets/BackToTop';
import ETAList from 'components/fragments/Markets/ETAList';
import SmallCube from 'assets/svg/small_cube.svg';
import { ReactComponent as FilterIcon } from 'assets/svg/filter-icon.svg';
import MarketsModalFilter from 'components/fragments/Markets/MarketsModalFilter';
import cn from 'classnames';
import { map } from 'lodash';
import { REGIONS } from 'common/consts';
import RegionCheckbox from 'components/fragments/Markets/RegionCheckbox';
import { Switch } from 'antd';
import { useContext } from 'react';
import { MainContext } from 'context/MainContext';
import { SearchOutlined } from '@ant-design/icons';
import './styles.scss';


const Markets: React.FC<MarketsProps> = (props: MarketsProps) => {
  const { expand } = useContext(MainContext);
  const [showMobileSearch, setShowMobileSearch] = useState(false);

  return (
    <div className="w-full h-full flex flex-col markets-page-container">
      <div className="w-full flex flex-row px-5 xl:py-[10px] eta-header text-[#474C55] bg-dark-header-linear-gradient h-[47px] max-h-[47px] xl:h-[75px] xl:max-h-[75px]">
        <div
          className="w-[16%] 2xl:w-[14%] flex flex-row gap-x-3 items-center cursor-pointer close-markets flex-1 text-[#C0C1C6] font-light"
          onClick={() => {
            props.closeMarkets();
            localStorage.setItem('defaultLightBox', '');
          }}
        >
          <button className="w-[25px] h-[25px] rounded-full bg-dark-action-gradient shadow-dark">X</button>
          CLOSE ETA MARKETS
        </div>

        {false && <div className="w-[10%] 2xl:w-[8%] px-2 4xl:px-5 flex flex-col border-l border-[#878C95] items-center justify-center eta-switch text-center">
          <Switch
            className="markets-switch"
            checked={props.allShown}
            onChange={() => props.setAllShown(!props.allShown)}
          />
          <span className="font-dinCondensed text-[13.4312px] leading-[13px] text-white mt-[7px]">
            ETAs ONLY
          </span>
        </div>}
        {false && <div className="w-[21%] px-5 flex border-l border-[#878C95] items-end mb-2 search-market">
          <SearchOutlined
            className={`${
              showMobileSearch ? '' : 'hide-search-icon '
            }search-mobile-icon-left`}
            onClick={() => setShowMobileSearch(!showMobileSearch)}
          />
          <input
            type="text"
            className={`${
              showMobileSearch ? '' : 'hide-mobile-search '
            }py-1 px-3 text-center w-full seach-eta rounded-2xl`}
            placeholder="Search for a share"
            value={props.searchValue}
            onChange={(e) => props.setSearchValue(e.target.value)}
          />
          <div
            className={`${
              showMobileSearch ? 'hide-search-icon ' : ''
            }search-mobile`}
            onClick={() => setShowMobileSearch(!showMobileSearch)}
          >
            <SearchOutlined className="search-mobile-icon" />
            <span>Search</span>
          </div>
        </div>}
        {false && <div className="hidden w-[15%] open-filters flex-row items-center justify-end gap-x-3">
          <span className="mb-0 text-xs leading-none text-white">FILTERS</span>
          <FilterIcon
            className="cursor-pointer"
            onClick={() => props.setShowModal(true)}
            fill={props.showModal ? '#363942' : '#A5A7AC'}
          />
        </div>}
        <div className="w-[25%] 2xl:w-[23%] flex pl-8 items-center gap-x-3 hide-mobile">
          <div className="flex-1 text-[#C0C1C6] font-light">REGION</div>
          <div className="flex flex-row w-full gap-x-1 4xl:gap-x-3">
            {map(REGIONS, (region) => {
              return (
                <RegionCheckbox
                  region={region}
                  handleChange={props.handleSelectRegion}
                  checked={props.selectedRegions.includes(region.value)}
                  key={region.name}
                />
              );
            })}
          </div>
        </div>
        <div className="w-[20%] flex items-center pl-8 hide-mobile relative">
          <select
            name="industry"
            className="prism-scrollbar about-scrollbar markets-new-dropdown mx-auto mb-0 mt-0 w-full text-[#C0C1C6]"
            value={props.industry || ''}
            onChange={(e) => {
              e.target.value === ''
                ? props.setIndustry(null)
                : props.setIndustry(e.target.value);
            }}
          >
            <option value="">INDUSTRY</option>
            {props.industries.map((industry) => (
              <option value={industry} key={industry}>
                {industry}
              </option>
            ))}
          </select>
          <svg className="absolute right-2.5" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.794599 6.75194L0.721182 6.67755L0.616663 6.67755L0.61375 6.67755L0.36375 6.67755L0.36375 6.92755L0.36375 7.69572L0.36375 7.79852L0.43607 7.87159L6.67607 14.1759L6.85375 14.3554L7.03143 14.1759L13.2714 7.87159L13.3437 7.79853L13.3437 7.69572L13.3437 6.92755L13.3437 6.31812L12.9158 6.75199L7.37176 12.3722L7.37176 1L7.37176 0.75L7.12176 0.75L6.59157 0.75L6.34157 0.75L6.34157 1L6.34157 12.3724L0.794599 6.75194Z" fill="#8B8E94" stroke="#8B8E94" stroke-width="0.5"/>
          </svg>
        </div>
        <div className="w-[20%] flex items-center pl-8 hide-mobile relative">
          <select
            name="sector"
            className="prism-scrollbar about-scrollbar markets-new-dropdown mx-auto mb-0 mt-0 w-full text-[#C0C1C6]"
            value={props.sector || ''}
            onChange={(e) => {
              e.target.value === ''
                ? props.setSector(null)
                : props.setSector(e.target.value);
            }}
          >
            <option value="">SECTOR</option>
            {props.sectors.map((sector) => (
              <option value={sector} key={sector}>
                {sector}
              </option>
            ))}
          </select>
          <svg className="absolute right-2.5" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.794599 6.75194L0.721182 6.67755L0.616663 6.67755L0.61375 6.67755L0.36375 6.67755L0.36375 6.92755L0.36375 7.69572L0.36375 7.79852L0.43607 7.87159L6.67607 14.1759L6.85375 14.3554L7.03143 14.1759L13.2714 7.87159L13.3437 7.79853L13.3437 7.69572L13.3437 6.92755L13.3437 6.31812L12.9158 6.75199L7.37176 12.3722L7.37176 1L7.37176 0.75L7.12176 0.75L6.59157 0.75L6.34157 0.75L6.34157 1L6.34157 12.3724L0.794599 6.75194Z" fill="#8B8E94" stroke="#8B8E94" stroke-width="0.5"/>
          </svg>
        </div>
      </div>
      <div
        className="flex flex-row h-full overflow-y-auto p-0 font-din2014 animate__animated animate__slideInDown relative markets-table"
        ref={props.marketsRef}
      >
        <div className='flex-col w-full flex'>
          <MarketsTableHeader
            securitySort={props.securitySort}
            setSecuritySort={props.setSecuritySort}
            setAllShown={props.setAllShown}
            sortSecurities={props.sortSecurities}
          />
          <MarketsTableBody
            virtuoso={props.virtuoso}
            selectedEtas={props.selectedEtas}
            securityList={props.securityList}
            handleETAClick={props.handleETAClick}
          />
          <BackToTop handleClick={props.backToTop} />
        </div>
      </div>
      {props.showModal && expand && (
        // the filter when the left hand view is expanded
        <MarketsModalFilter
          showModal={props.showModal}
          selectedRegions={props.selectedRegions}
          setShowModal={props.setShowModal}
          industries={props.industries}
          sectors={props.sectors}
          industry={props.industry}
          sector={props.sector}
          setIndustry={props.setIndustry}
          setSector={props.setSector}
          setSelectedRegions={props.setSelectedRegions}
        />
      )}
    </div>
  );
};

export default Markets;
