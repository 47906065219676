import { FC, useContext, useEffect } from 'react';
import LeftHandSide from 'components/layouts/LeftHandSide';
import RightHandSide from 'components/layouts/RightHandSide';
import { About } from 'components/articles'; 
import { MainContext } from 'context/MainContext';

const AboutContainer: FC = () => {
	const { expand, setExpand } = useContext(MainContext);

  useEffect(() => {
    setExpand(true);
  }, [])

	return (
    <>
      <LeftHandSide className="block">
        <About expanded={expand} />
      </LeftHandSide>
      <RightHandSide className="hidden lg:block">
        <div className="w-full h-full bg-[#56565663] bg-cubic-bg-lhs bg-no-repeat bg-right bg-cover" />
      </RightHandSide>
    </>
  );
};

export default AboutContainer;
