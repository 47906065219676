import { LoginModalProps } from './LoginModal.props';
import { LockOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import './styles.scss'

const LoginModalView: React.FC<LoginModalProps> = (props: LoginModalProps) => {
  const onFieldChange = (e: any) => {
    props.setPassword(e.target.value);
  };

  return (
    <>
      <div className="absolute cursor-pointer w-full h-full modal-backdrop block z-40 bg-[#56565663] bg-cubic-bg-rhs bg-no-repeat bg-center bg-cover" />
        <div className="transition-width duration-300 max-w-sm font-din2014 inset-x-0 relative flex flex-col rounded-2xl mx-auto py-8 px-10 items-center self-center animate__animated animate__slideInDown z-50 !w-10/12 md:!w-1/2 lg:!w-2/3 bg-blur-linear-gradient backdrop-blur login-box-shadow xl:!w-3/4">
          <h2 className="self-center text-center text-[32px] font-extralight text-[#FFFFFF] mb-1">
            PRISM Perspective
          </h2>
          <h3 className="self-center text-center text-base font-extralight text-[#D9D9D9] mb-7">
            See the value within whole shares
          </h3>
          <div className="flex flex-col items-center w-full">
            <form onSubmit={props.handleLogin} className="w-full flex flex-col prism-login-form">
              <Input
                size="large"
                name="password"
                className="password mb-4 text-[#D9D9D9] bg-black bg-black-gradient"
                placeholder="Enter password"
                type="password"
                value={props.password || ''}
                onChange={onFieldChange}
                prefix={<LockOutlined className="site-form-item-icon text-[#D9D9D9]" />}
                status={props.error ? 'error' : ''}
                style={{
                  borderRadius: '20px',
                }}
              />

              <button
                className="text-[#D9D9D9] text-sm py-1 px-3 self-end bg-black bg-black-gradient rounded-[20px]"
                type="submit"
              >
                LOGIN
              </button>
            </form>
          </div>
        </div>
    </>
  );
};

export default LoginModalView;
