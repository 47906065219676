import { MarketsTableETAProps } from './MarketsTableETA.props';
import MarketsTableETAContent from '../MarketsTableETAContent';
import cn from 'classnames';
import { useContext, useState } from 'react';
import { Tab } from '@headlessui/react';
import ETATab from '../ETATab';
import { MainContext } from 'context/MainContext';
import './styles.scss';

const MarketsTableETAView: React.FC<MarketsTableETAProps> = (
  props: MarketsTableETAProps
) => {
  const { expand } = useContext(MainContext);
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div
      className={cn(
        'flex-row bg-eta-content',
        props.shown ? 'flex' : 'hidden',
        expand ? 'eta-expand' : ''
      )}
    >
      <div className="flex flex-col w-full pt-3 pb-6 px-3">
        <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
          <Tab.List className="justify-center text-lg pt-4 flex flex-row gap-x-2 relative">
            <Tab>
              <ETATab
                fill='transparent'
                stroke={selectedTab === 0 ? '#C0C1C6' : '#5F6369'}
                width={166}
                height={27}
                path={
                  <path d="M165 26.5L157.465 6.91021C155.98 3.04829 152.27 0.5 148.132 0.5H17.868C13.7303 0.5 10.0199 3.04829 8.53453 6.91022L1 26.5" stroke={selectedTab === 0 ? "#D9D9D9" : "#5F6369"} />
                }
              >
                <span className="text-[20px]">GROWTH</span>
              </ETATab>
            </Tab>
            <Tab>
              {/*<svg width="166" height="27" viewBox="0 0 166 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M165 26.5L157.465 6.91021C155.98 3.04829 152.27 0.5 148.132 0.5H17.868C13.7303 0.5 10.0199 3.04829 8.53453 6.91022L1 26.5" stroke="#D9D9D9"/>
              </svg>*/}
              <ETATab
                fill='transparent'
                stroke={selectedTab === 1 ? '#C0C1C6' : '#5F6369'}
                width={166}
                height={27}
                path={
                  <path d="M165 26.5L157.465 6.91021C155.98 3.04829 152.27 0.5 148.132 0.5H17.868C13.7303 0.5 10.0199 3.04829 8.53453 6.91022L1 26.5" stroke={selectedTab === 1 ? "#D9D9D9" : "#5F6369"}/>
                }
              >
                <span className="text-[20px]">DIVIDEND</span>
              </ETATab>
            </Tab>
            <div className="absolute inset-0 security-border-bottom"></div>
          </Tab.List>
          {/* The content of the eta table */}
          <Tab.Panels className="px-3">
            <Tab.Panel>
              <MarketsTableETAContent
                etas={props.growthETAs}
                ticker={props.security.ticker}
                selectedEtas={props.selectedEtas}
                security={props.security}
                type="MULTIPLE"
                handleETAClick={props.handleETAClick}
              />
            </Tab.Panel>
            <Tab.Panel>
              <MarketsTableETAContent
                etas={props.divETAs}
                ticker={props.security.ticker}
                selectedEtas={props.selectedEtas}
                security={props.security}
                type="YIELD"
                handleETAClick={props.handleETAClick}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default MarketsTableETAView;
