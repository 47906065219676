const EquityOuterCube = ({ shadowOnly = false }: { shadowOnly?: boolean }) => (
  <svg width="478" height="551" viewBox="0 0 478 551" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_iiii_1_690)">
      <path d="M239 0.5L477.157 138V413L239 550.5L0.843018 413V138L239 0.5Z" fill="#212125"/>
    </g>
    <path d="M1.34302 138.289L239 1.07735L476.657 138.289V412.711L239 549.923L1.34302 412.711V138.289Z" stroke="url(#paint0_linear_1_690)"/>
    <defs>
      <filter id="filter0_iiii_1_690" x="-19.157" y="-19.5" width="516.314" height="590" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="20" dy="20"/>
        <feGaussianBlur stdDeviation="25"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.0627451 0 0 0 0.9 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1_690"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-20" dy="-20"/>
        <feGaussianBlur stdDeviation="20"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.203922 0 0 0 0 0.203922 0 0 0 0 0.227451 0 0 0 0.9 0"/>
        <feBlend mode="normal" in2="effect1_innerShadow_1_690" result="effect2_innerShadow_1_690"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="20" dy="-20"/>
        <feGaussianBlur stdDeviation="20"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.0627451 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="effect2_innerShadow_1_690" result="effect3_innerShadow_1_690"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="-20" dy="20"/>
        <feGaussianBlur stdDeviation="20"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.054902 0 0 0 0 0.054902 0 0 0 0 0.0627451 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="effect3_innerShadow_1_690" result="effect4_innerShadow_1_690"/>
      </filter>
      <linearGradient id="paint0_linear_1_690" x1="239" y1="0.5" x2="239" y2="550.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0"/>
        <stop offset="1"/>
      </linearGradient>
    </defs>
  </svg>
);

export default EquityOuterCube;
