import { useContext } from 'react';
import { ETAHeaderProps } from './ETAHeader.props';
import SmallCube from 'assets/svg/small_cube.svg';
import { Switch } from 'antd';
import { MainContext } from 'context/MainContext';
import { AuthContext } from 'context/AuthContext';
import { MinifiedSecurityDropdown } from '../SecurityDropdown';
import { SecurityDropdown } from 'components/snippets';
import { formatCurrency } from '../../../NewLearnMoreMarket/utils';
import { CURRENCIES } from 'common/consts';
import { CURRENCY_SYMBOLS } from 'common/consts';
import cn from 'classnames';

const ETAHeader: React.FC<ETAHeaderProps> = (props: ETAHeaderProps) => {
  const { expand } = useContext(MainContext);
  const { isLogin } = useContext(AuthContext);

  return (
    <>
      <div
        className={cn(
          'flex flex-row eta-index-container z-[5] relative px-5 xl:mb-0 min-h-[75px] justify-between bg-dark-header-linear-gradient',
          !isLogin ? 'is-login' : ''
        )}
        style={{ display: isLogin ? 'none' : '' }}
      >
        <span>ACCESS MARKETS</span>
      </div>
      <div
        className={cn(
          'flex flex-row items-center eta-index-container z-[100] relative px-10 xl:mb-0 min-h-[75px] justify-between bg-dark-header-linear-gradient',
          !isLogin ? 'invisible' : ''
        )}
        style={{ display: !isLogin ? 'none' : '' }}
      >
        {props.activeSecurity?.ticker && <>      
          <div className="flex-shrink-1">
            <div className="text-white text-[25px] font-light">{CURRENCY_SYMBOLS.get(props.activeSecurity?.region)}{props.activeSecurity?.lastPrice}</div>
            <div className="text-[#C0C1C6] text-[14px]">Underlying Price</div>
          </div>

          <div className="horizontal-separator" />

          <div>
            <div className="text-white text-[28px] font-bold text-center">{props.activeSecurity?.ticker}</div>
            <div className="text-[#C0C1C6] text-[14px] text-center uppercase">{props.activeSecurity?.longName}</div>
          </div>

          <div className="horizontal-separator" />

          <div className="flex-shrink-1">
            <div className="text-white text-[25px] font-light text-right">{props.yieldValue}%</div>
            <div className="text-[#C0C1C6] text-[14px] text-right">Underlying Yield</div>
          </div>
        </>}

      </div>
    </>
  );
};

export default ETAHeader;
