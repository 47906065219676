import { AboutProps } from './About.props';
import DIRECTORS from 'common/consts/directors';
import DirectorHeader from './DirectorHeader';
import DirectorContent from './DirectorContent';
import { Collapse } from 'antd';
import './styles.scss';

const { Panel } = Collapse;

const AboutView: React.FC<AboutProps> = (props: AboutProps) => {
  return (
    <div
      className={`p-10 h-full w-full bg-cubic-bg-lhs bg-no-repeat bg-left bg-cover prism-scrollbar about-scrollbar overflow-y-auto overflow-x-hidden ${props.expanded ? 'expand' : ''
        }`}
    >
      <div className="max-w-md font-din2014">
        <h2 className="text-[52px] text-[#C0C1C6] font-light">About us</h2>
        <p className="text-[30px] text-white font-light mt-10">
          Prism’s unique innovation brings a suite of investment instruments to the global equity markets.
        </p>
        <p className="text-[30px] text-white font-light mt-10">
          These are available on well-known global stock exchanges, empowering investors with greater design over their portfolios.
        </p>
      </div>
    </div>
  );
};

export default AboutView;
