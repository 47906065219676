import { Col, Image, Row } from 'antd';
import { mapList } from 'components/subpages/map/map.data';

const Locations: React.FC = () => {
  return (
    <div className="p-14 flex flex-col gap-y-8">
      {mapList?.map((location) => (
        <div>
          <Row>
            <div className="text-[38px] text-white font-light capitalize">{location.name}</div>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Image
                className="object-cover"
                src={location.imageUrl}
                preview={false}
              />
            </Col>
            <Col span={10}>
              <Image
                className="object-cover"
                src={location.locationImageUrl}
                preview={false}
              />
            </Col>
            <Col span={8}>
              <div className="text-white text-[16px]">
                <div className="text-white font-bold">Address</div>
                <p className="font-light text-base tracking-wide text-white">
                  {location.contact.address}
                </p>
                <p className="font-light text-base tracking-wide text-white mb-0">
                  <strong className="font-bold">T</strong>{' '}
                  {location.contact.phone}
                </p>
                <p className="font-light text-base tracking-wide text-white">
                  <strong className="font-bold">E</strong>{' '}
                  {location.contact.email}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default Locations;
